<template>
  <div class="center">
    <div class="box-container-flex">
      <PageRecoverPasswordLeft />
      <PageRecoverPasswordRightWithToken v-if="hasToken" :token="token" />
      <PageRecoverPasswordRight v-else />
    </div>
  </div>
</template>

<script>
import PageRecoverPasswordLeft from './PageRecoverPasswordLeft.vue';
import PageRecoverPasswordRight from './PageRecoverPasswordRight.vue';
import PageRecoverPasswordRightWithToken from './PageRecoverPasswordRightWithToken.vue';

export default {
  components: {
    PageRecoverPasswordLeft,
    PageRecoverPasswordRight,
    PageRecoverPasswordRightWithToken
  },
  data() {
    return {
      token: null,
      hasToken: false
    };
  },
  created() {
    const { token } = this.$route.query;
    if (token) {
      this.token = token;
      this.hasToken = true;
    }
  }
};
</script>

<style lang="scss">
.center {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.box-container-flex {
  display: flex;
}
.box {
  border: 1px solid black;
  min-height: 500px;
  min-width: 500px;
  .container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
</style>
