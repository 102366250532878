import PageContent from './components/PageContent.vue';
import PageContato from './components/PageContato.vue';
import PageHome from './components/PageHome.vue';
import PageNews from './components/PageNews.vue';
import PageNewsContent from './components/PageNewsContent.vue';
import PageNotFound from './components/PageNotFound.vue';
import PageLogin from './components/PageLogin.vue';
import PageRecoverPassword from './components/PageRecoverPassword.vue';

export default [
  {
    path: '/contato',
    component: PageContato
  },
  {
    path: '/associacao/sobre',
    component: PageContent,
    props: {
      id: 1
    }
  },
  {
    path: '/associacao/memoria',
    component: PageContent,
    props: {
      id: 2
    }
  },
  {
    path: '/associacao/gestoes',
    component: PageContent,
    props: {
      id: 3
    }
  },
  {
    path: '/beneficios/financeiro',
    component: PageContent,
    props: {
      id: 4
    }
  },
  {
    path: '/beneficios/medicamento',
    component: PageContent,
    props: {
      id: 5
    }
  },
  {
    path: '/beneficios/espaco',
    component: PageContent,
    props: {
      id: 6
    }
  },
  {
    path: '/beneficios/esportes',
    component: PageContent,
    props: {
      id: 7
    }
  },
  {
    path: '/beneficios/eventos',
    component: PageContent,
    props: {
      id: 8
    }
  },
  {
    path: '/parcerias/advocacia',
    component: PageContent,
    props: {
      id: 9
    }
  },
  {
    path: '/parcerias/cultura',
    component: PageContent,
    props: {
      id: 10
    }
  },
  {
    path: '/parcerias/entretenimento',
    component: PageContent,
    props: {
      id: 11
    }
  },
  {
    path: '/parcerias/esporte',
    component: PageContent,
    props: {
      id: 12
    }
  },
  {
    path: '/parcerias/gastronomia',
    component: PageContent,
    props: {
      id: 13
    }
  },
  {
    path: '/parcerias/saude_estetica',
    component: PageContent,
    props: {
      id: 14
    }
  },
  {
    path: '/parcerias/seguro_dinheiro',
    component: PageContent,
    props: {
      id: 15
    }
  },
  {
    path: '/noticias/:permalink',
    component: PageNewsContent
  },
  {
    path: '/noticias',
    component: PageNews
  },
  {
    path: '/login',
    component: PageLogin
  },
  {
    path: '/recuperarSenha',
    component: PageRecoverPassword
  },
  {
    path: '/',
    component: PageHome
  },
  {
    path: '/404',
    name: 'pageNotFound',
    component: PageNotFound
  },
  {
    path: '*',
    redirect: '/404'
  }
];
