<template>
  <PageLayout>
    <h3>Página não encontrada!</h3>
  </PageLayout>
</template>

<script>
import PageLayout from '@/app/layouts/PageLayout.vue';

export default {
  components: {
    PageLayout
  }
};
</script>
