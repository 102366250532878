<template>
  <b-navbar toggleable="lg" type="light">
    <b-navbar-toggle target="nav_collapse" />
    <b-collapse id="nav_collapse" is-nav class="justify-content-center">
      <!-- left nav items -->
      <b-navbar-nav>
        <template v-for="item in items">
          <b-nav-item v-if="item.path" :key="item.label" href="#" :to="{ path: item.path }">{{
            item.label
          }}</b-nav-item>
          <b-nav-item-dropdown v-else :key="item.label" :text="item.label">
            <b-dropdown-item v-for="child in item.children" :key="child.path" href="#" :to="{ path: child.path }">{{
              child.text
            }}</b-dropdown-item>
          </b-nav-item-dropdown>
        </template>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<script>
import navbar from '../navbar.js';

export default {
  data() {
    return navbar;
  }
};
</script>

<style lang="scss">
.navbar {
  padding: 3px;
  //border: 0.7px solid #e5e5e5;
  margin-bottom: 1rem;

  .nav-link {
    font-weight: 400;
    color: black !important;
    &:hover {
      color: black !important;
    }
  }

  .navbar-nav {
    border-bottom: 1px solid;
  }
}
</style>
