<template>
  <PageLayout>
    <b-container>
      <Breadcrumb />
      <h3 class="text-left">Use o formulário para enviar um e-mail</h3>
      <b-row class="contato-grid text-left">
        <b-col>
          <p>
            <small>(todos os campos são obrigatórios)</small>
          </p>
          <PageContatoForm />
        </b-col>
        <b-col>
          <p><strong>Telefones AFEAESP: </strong>+55 11 3799-7731 ou +55 11 3799-3551</p>
          <p><strong>Endereço: </strong>Rua Itapeva, 432 - Bela Vista - São Paulo/SP - CEP 01332-000</p>
          <PageContatoMap />
        </b-col>
      </b-row>
    </b-container>
  </PageLayout>
</template>

<script>
import PageLayout from '@/app/layouts/PageLayout.vue';
import Breadcrumb from './Breadcrumb.vue';
import PageContatoForm from './PageContatoForm.vue';
import PageContatoMap from './PageContatoMap.vue';

export default {
  components: {
    Breadcrumb,
    PageLayout,
    PageContatoForm,
    PageContatoMap
  },
  data() {
    return {};
  }
};
</script>
