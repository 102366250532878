export default {
  footerBottomText:
    '©2019 AFEAESP • Associação dos Funcionários da FGV • Todos os direitos reservados • Rua Itapeva 432 • São Paulo',
  items: [
    {
      label: 'Página Inicial',
      path: '/'
    },
    {
      label: 'Associação',
      children: [
        {
          path: '/associacao/sobre',
          text: 'Sobre a AFEAESP'
        },
        {
          path: '/associacao/memoria',
          text: 'Memória AFEAESP'
        },
        {
          path: '/associacao/gestoes',
          text: 'Gestões'
        }
      ]
    },
    {
      label: 'Benefícios',
      children: [
        {
          path: '/beneficios/financeiro',
          text: 'Auxílio financeiro'
        },
        {
          path: '/beneficios/medicamento',
          text: 'Auxílio medicamento'
        },
        {
          path: '/beneficios/espaco',
          text: 'Espaço AFEAESP'
        },
        {
          path: '/beneficios/esportes',
          text: 'Esportes'
        },
        {
          path: '/beneficios/eventos',
          text: 'Eventos comemorativos'
        }
      ]
    },
    {
      label: 'Parcerias',
      children: [
        {
          path: '/parcerias/advocacia',
          text: 'Advocacia'
        },
        {
          path: '/parcerias/cultura',
          text: 'Cultura'
        },
        {
          path: '/parcerias/entretenimento',
          text: 'Entretenimento'
        },
        {
          path: '/parcerias/esporte',
          text: 'Esporte'
        },
        {
          path: '/parcerias/gastronomia',
          text: 'Gastronomia'
        },
        {
          path: '/parcerias/saude_estetica',
          text: 'Saúde e Estética'
        },
        {
          path: '/parcerias/seguro_dinheiro',
          text: 'Seguro e Dinheiro'
        }
      ]
    },
    {
      label: 'Notícias',
      path: '/noticias'
    },
    {
      label: 'Contato',
      path: '/contato'
    }
  ]
};
