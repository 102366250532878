<template>
  <PageLayout>
    <b-container>
      <b-row class="text-justify">
        <b-col>
          <h5>\ Notícias</h5>
          <News :top="2" />
        </b-col>
        <b-col>
          <h5>\ {{ destaque.titulo }}</h5>
          <div v-if="showDestaque" class="content" v-html="destaque.conteudo"></div>
        </b-col>
      </b-row>
    </b-container>
  </PageLayout>
</template>

<script>
import PageLayout from '@/app/layouts/PageLayout.vue';
import ContentService from '@/services/content-service';
import News from './News.vue';

export default {
  components: {
    News,
    PageLayout
  },
  data() {
    return {
      destaque: {}
    };
  },
  computed: {
    showDestaque() {
      return this.destaque && this.destaque.conteudo;
    }
  },
  created() {
    return this.fetchData();
  },
  methods: {
    async fetchData() {
      try {
        const res = await ContentService.getPageContent(17);
        this.destaque = res.data;
      } catch (err) {
        console.error(err);
      }
    }
  }
};
</script>
