import axios from 'axios';

const userStorageKey = 'afeaesp_session_user';
const tokenStorageKey = 'afeaesp_session_token';

// Add a response interceptor
axios.interceptors.response.use(
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  function(response) {
    return response;
  },
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  function(error) {
    if (error.response.status === 401 || error.response.status === 403) {
      Session.invalidate();
    }
    return Promise.reject(error);
  }
);

const Session = {
  onInvalidate() {},
  async login({ username, password, remember }) {
    try {
      const result = await axios({
        url: '/api/v1/login',
        method: 'POST',
        auth: {
          username,
          password
        }
      });

      this.setSessionUserAndToken(result.data, remember);
      this.setAuthHeader(result.data.token);

      return {
        auth: result.data.auth,
        redirectTo: result.data.redirectTo
      };
    } catch (err) {
      return false;
    }
  },
  clear() {
    // delete token
    sessionStorage.removeItem(tokenStorageKey);
    localStorage.removeItem(tokenStorageKey);
    // delete user info
    sessionStorage.removeItem(userStorageKey);
    localStorage.removeItem(userStorageKey);
  },
  goToLogin() {
    window.location.href = '/#/login';
  },
  loadUserSession() {
    const token = this.getSessionToken();
    if (token) {
      this.setAuthHeader(token);
      return true;
    }
    return false;
  },
  setAuthHeader(token) {
    // set jwt token in the user session
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  },
  setSessionUserAndToken(payload, remember) {
    const token = payload.token;
    delete payload.token;

    let storage = sessionStorage;
    if (remember) {
      storage = localStorage;
    }
    storage.setItem(tokenStorageKey, token);
    storage.setItem(userStorageKey, Buffer.from(JSON.stringify(payload)).toString('base64'));
  },
  getSessionUser() {
    let payload = localStorage.getItem(userStorageKey);
    if (!payload) {
      payload = sessionStorage.getItem(userStorageKey);
    }
    if (payload) {
      return JSON.parse(Buffer.from(payload, 'base64').toString('utf8'));
    }
    return null;
  },
  getSessionToken() {
    let token = localStorage.getItem(tokenStorageKey);
    if (!token) {
      token = sessionStorage.getItem(tokenStorageKey);
    }
    return token;
  },
  invalidate() {
    this.clear();
    this.onInvalidate();
  },
  openUrl(url, target) {
    window.open(this.getSessionUrl(url), target || '_self');
  },
  openAdmin(redirectTo) {
    if (redirectTo) {
      window.location.href = '/admin#/?redirect_to=' + encodeURIComponent(redirectTo);
    } else {
      window.location.href = '/admin';
    }
  },
  getSessionUrl(url) {
    const token = this.getSessionToken();
    if (token && url.indexOf('token=') === -1) {
      if (url.indexOf('?') > -1) {
        url += '&token=' + token;
      } else {
        url += '?token=' + token;
      }
    }
    return url;
  },
  isAdmin() {
    if (Session.isActive()) {
      const payload = this.getSessionUser();
      return payload && payload.admin;
    }
    return false;
  },
  isActive() {
    return this.getSessionToken() !== null;
  }
};

export default Session;
