<template>
  <section class="news text-left">
    <article v-for="item in items" :key="item.id">
      <h6>{{ item.titulo }}</h6>
      <small>{{ item.date }}</small>
      <p>
        <i>{{ item.subtitulo }}</i>
      </p>
      <router-link :to="'/noticias/' + item.permalink">Saiba mais...</router-link>
      <hr />
    </article>
  </section>
</template>

<script>
import axios from 'axios';
import moment from 'moment';

moment.locale('pt-br');

export default {
  props: {
    top: {
      type: Number,
      default: 10
    }
  },
  data() {
    return {
      items: []
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      const result = await axios.get('/api/v1/public/noticias/top/10');
      const data = result.data;
      data.forEach(news => {
        news.date = moment(news.createdAt).format('LL');
      });
      this.items = data;
    }
  }
};
</script>

<style></style>
