<template>
  <div>
    <b-form @submit="onSubmit" @reset="onReset">
      <b-form-group label="Email:" label-for="email">
        <b-form-input id="email" v-model="form.email" type="email" required />
      </b-form-group>

      <b-form-group label="Nome:" label-for="nome">
        <b-form-input id="nome" v-model="form.name" type="text" required />
      </b-form-group>

      <b-form-group label="Assunto:" label-for="assunto">
        <b-form-select id="assunto" v-model="form.subject" :options="assuntos" required />
      </b-form-group>

      <b-form-group label="Mensagem:" label-for="mensagem">
        <b-form-textarea id="mensagem" v-model="form.message" rows="3" max-rows="6" required :maxlength="500" />
      </b-form-group>

      <p v-if="showErrorMessage" class="alert alert-danger">
        Desculpe, ocorreu um erro. Por favor, tente novamente mais tarde.
      </p>
      <p v-if="showSuccessMessage" class="alert alert-success">
        Recebemos seu contato e em breve retornaremos através do email informado.
      </p>
      <b-button-group v-else>
        <b-button type="submit" variant="primary" :disabled="disableUI">Enviar</b-button>
        <b-button type="reset" variant="danger" :disabled="disableUI">Limpar</b-button>
      </b-button-group>
    </b-form>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      form: {
        email: '',
        name: '',
        subject: null,
        message: ''
      },
      assuntos: [
        {
          text: 'Selecione',
          value: null
        },
        'Sugestões',
        'Reclamações',
        'Estacionamento',
        'Quero me associar',
        'Nova parceria',
        'Outros'
      ],
      disableUI: false,
      showSuccessMessage: false,
      showErrorMessage: false
    };
  },
  methods: {
    async onSubmit(evt) {
      evt.preventDefault();

      this.disableUI = true;

      try {
        const res = await axios.post('/api/v1/contato', this.form);
        if (res.status === 200) {
          this.showSuccessMessage = true;
        }
      } catch (err) {
        console.error(err);
        this.showErrorMessage = true;
      } finally {
        this.disableUI = false;
        this.resetForm();
      }
    },
    onReset(evt) {
      evt.preventDefault();
      this.resetForm();
    },
    resetForm() {
      /* Reset our form values */
      this.form.email = '';
      this.form.name = '';
      this.form.subject = null;
      this.form.message = '';
      /* Trick to reset/clear native browser form validation state */
      this.disableUI = false;
      this.$nextTick(() => {
        this.disableUI = true;
      });
    }
  }
};
</script>
