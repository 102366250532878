<template>
  <PageLayout>
    <b-container>
      <News :top="2" />
    </b-container>
  </PageLayout>
</template>

<script>
import News from './News.vue';
import PageLayout from '@/app/layouts/PageLayout.vue';

export default {
  components: {
    News,
    PageLayout
  }
};
</script>

<style></style>
