<template>
  <div class="box box-right">
    <div class="container">
      <h3>Login</h3>
      <hr />
      <p class="welcome text-left">
        Seja bem vindo! Esta é uma área para usuários associados à AFEAESP.<br />Para continuar, por favor faça o login
        com a sua conta.
      </p>

      <b-alert v-model="showErrorMessage" variant="danger" show><strong>Dados de login inválidos.</strong></b-alert>

      <b-form v-if="show" @submit="onSubmit">
        <b-form-group label="Email ou Código FVG:" class="text-left">
          <b-form-input v-model="form.username" type="text" required />
        </b-form-group>

        <b-form-group label="Senha:" class="text-left">
          <b-form-input v-model="form.password" type="password" required />
        </b-form-group>

        <b-row>
          <b-form-checkbox v-model="form.remember" class="col-md-6 remember">
            Lembrar-me
          </b-form-checkbox>
          <router-link class="col-md-6 forgot" href="#" to="/recuperarSenha">Esqueceu a senha?</router-link>
        </b-row>

        <b-button type="submit" variant="danger" :disabled="disableUI">Login</b-button>
      </b-form>

      <b-row class="d-md-none pb-3">
        <b-col>
          <b-button>Quero ser um Associado!</b-button>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import Session from '@/services/session';

export default {
  data() {
    return {
      show: true,
      showErrorMessage: false,
      form: {
        username: '',
        password: '',
        remember: true
      },
      disableUI: false,
      loaded: false
    };
  },
  methods: {
    async onSubmit(evt) {
      evt.preventDefault();
      this.disableUI = true;
      const res = await Session.login(this.form);
      if (res && res.auth) {
        Session.openAdmin(this.$route.query.redirect_to);
      } else {
        this.showErrorMessage = true;
        this.disableUI = false;
      }
    }
  }
};
</script>

<style lang="scss">
.box-right {
  hr {
    margin-left: 0;
    margin-right: 0;
  }
  button {
    min-width: 200px;
    margin-top: 40px;
  }
  .welcome,
  .remember,
  .forgot {
    font-size: 14px;
  }
}
</style>
