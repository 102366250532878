<template>
  <PageLayout>
    <b-container>
      <h1 v-html="page.titulo">{{ page.titulo }}</h1>
      <h6>
        <small>
          <i>{{ date }}</i>
        </small>
      </h6>
      <PageContentMarkdown :value="page.conteudo" />
    </b-container>
  </PageLayout>
</template>

<script>
import PageLayout from '@/app/layouts/PageLayout.vue';
import ContentService from '@/services/content-service';
import PageContentMarkdown from './PageContentMarkdown.vue';
import moment from 'moment';

moment.locale('pt-br');

export default {
  components: {
    PageContentMarkdown,
    PageLayout
  },
  data() {
    return {
      page: {},
      date: ''
    };
  },
  mounted() {
    return this.fetchData();
  },
  methods: {
    async fetchData() {
      try {
        const res = await ContentService.getNewsContent(this.$route.params.permalink);
        this.page = res.data;
        this.date = moment(this.page.createdAt).format('LL');
      } catch (err) {
        console.error(err);
        // force the component destroy to remove it from the keep-alive cache
        this.$destroy();
        this.$router.replace('/404');
      }
    }
  }
};
</script>

<style lang="scss">
.breadcrumb {
  margin-top: 10px;
}
</style>
