<template>
  <footer id="footer">
    <nav>
      <ul v-for="item in parents" :key="item.label">
        <label v-if="item.children">\ {{ item.label }}</label>
        <router-link v-for="child in item.children" :key="child.path" :to="{ path: child.path }" tag="li">{{
          child.text
        }}</router-link>
      </ul>
      <ul>
        <label>\ Outros</label>
        <router-link v-for="item in others" :key="item.label" :to="{ path: item.path }" tag="li">
          {{ item.label }}
        </router-link>
      </ul>
    </nav>
    <div id="footer-bottom" class="text-center">
      <div class="row flex-row">
        <div class="col-8 text-right">
          <img class="logo-sm" src="/img/afeaesp-logo-sm.gif" />
          <span>{{ footerBottomText }}</span>
          <div class="float-right"></div>
        </div>
        <div class="col text-left">
          <span>|</span>
          <a href="https://www.facebook.com/afeaesp">
            <img src="/img/icon_facebook.gif" />
          </a>
          <a href="https://twitter.com/AFEAESP">
            <img src="/img/icon_twitter.gif" />
          </a>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import navbar from '@/app/navbar';

export default {
  data() {
    return navbar;
  },
  computed: {
    parents() {
      return this.items.filter(item => item.hasOwnProperty('children'));
    },
    others() {
      return this.items.filter(item => !item.hasOwnProperty('children'));
    }
  }
};
</script>
