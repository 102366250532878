import axios from 'axios';

const baseUrl = '/api/v1/public';

export default {
  getPageContent(pageId) {
    return axios.get(`${baseUrl}/paginas/${pageId}/conteudo`);
  },
  getNewsContent(permalink) {
    return axios.get(`${baseUrl}/noticias/${permalink}`);
  }
};
