<template>
  <a class="btn btn-sm btn-outline-secondary" href="#" @click="login">Área dos Associados</a>
</template>

<script>
import Session from '@/services/session';

export default {
  methods: {
    login() {
      if (Session.isActive()) {
        Session.openAdmin();
      } else {
        this.$router.push('/login');
      }
    }
  }
};
</script>
