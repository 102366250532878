<template>
  <div class="map-wrapper">
    <iframe :src="url" width="600" height="450" frameborder="0" style="border:0;"></iframe>
  </div>
</template>

<script>
export default {
  data() {
    return {
      url:
        // eslint-disable-next-line max-len
        'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3657.246929846595!2d-46.65546078507245!3d-23.55957336741114!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94ce59c9856ad2a9%3A0x66f9f704a250b63!2sR.%20Itapeva%2C%20432%20-%20Bela%20Vista%2C%20S%C3%A3o%20Paulo%20-%20SP%2C%2001332-010!5e0!3m2!1sen!2sbr!4v1573850373370!5m2!1sen!2sbr'
    };
  }
};
</script>
