<template>
  <div class="box box-right">
    <div class="container">
      <h3>Esqueceu sua Senha?</h3>
      <hr />
      <p class="welcome text-left">
        Não tem problema. Basta nos informar qual seu email que em breve enviaremos um email para recuperar sua senha.
      </p>

      <b-form v-if="show" @submit="onSubmit">
        <b-form-group label="Email:" class="text-left">
          <b-form-input v-model="form.email" type="text" required />
        </b-form-group>

        <b-button v-if="!showSuccessMessage" type="submit" variant="danger" :disabled="disableUI">
          <b-spinner v-show="disableUI" small type="grow"></b-spinner>
          Recuperar minha senha
        </b-button>
        <p v-if="showSuccessMessage" class="alert-success">
          Um email será enviado em breve com informações para recuperar sua senha. Verifique também a sua caixa de
          SPAM.<br />
          Clique <router-link to="/">AQUI</router-link> para voltar.
        </p>
      </b-form>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      show: true,
      showErrorMessage: false,
      showSuccessMessage: false,
      form: {
        email: ''
      },
      disableUI: false,
      loaded: false
    };
  },
  methods: {
    async onSubmit(evt) {
      evt.preventDefault();
      this.disableUI = true;
      try {
        await axios.post('/senha/recuperar', this.form);
        this.showSuccessMessage = true;
      } finally {
        this.disableUI = false;
      }
    }
  }
};
</script>

<style lang="scss">
.box-right {
  hr {
    margin-left: 0;
    margin-right: 0;
  }
  button {
    min-width: 200px;
    margin-top: 40px;
  }
  p {
    font-size: 14px;
  }
}
</style>
