<template>
  <div class="page-layout">
    <TheHeader />
    <TheNavBar />
    <div class="page-layout-content">
      <slot />
    </div>
    <TheFooter />
  </div>
</template>

<script>
import TheNavBar from '@/app/components/TheNavBar.vue';
import TheFooter from '@/app/components/TheFooter.vue';
import TheHeader from '@/app/components/TheHeader.vue';

export default {
  components: {
    TheNavBar,
    TheFooter,
    TheHeader
  }
};
</script>
