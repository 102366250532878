<template>
  <p v-show="!hidden" class="text-left breadcrumb">{{ value }}</p>
</template>

<script>
export default {
  props: {
    hidden: {
      type: Boolean,
      default: false
    },
    value: {
      type: String,
      default: ''
    }
  }
};
</script>

<style lang="scss" scope>
.page {
  .breadcrumb {
    padding: 0.5rem 0.5rem;
  }
}
</style>
