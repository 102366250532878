<template>
  <div class="center">
    <div class="box-container-flex">
      <PageLoginLeft />
      <PageLoginRight />
    </div>
  </div>
</template>

<script>
import PageLoginLeft from './PageLoginLeft.vue';
import PageLoginRight from './PageLoginRight.vue';

export default {
  components: {
    PageLoginLeft,
    PageLoginRight
  }
};
</script>

<style lang="scss">
.center {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.box-container-flex {
  display: flex;
}
.box {
  border: 1px solid black;
  min-height: 500px;
  min-width: 500px;
  .container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
</style>
