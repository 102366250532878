<template>
  <PageLayout>
    <b-container>
      <Breadcrumb :hidden="noBreadcrumb" :value="page.breadcrumb" />
      <h1 v-html="page.titulo">{{ page.titulo }}</h1>
      <PageContentMarkdown :value="page.conteudo" />
    </b-container>
  </PageLayout>
</template>

<script>
import PageLayout from '@/app/layouts/PageLayout.vue';
import ContentService from '@/services/content-service';
import PageContentMarkdown from './PageContentMarkdown.vue';
import Breadcrumb from './Breadcrumb.vue';

export default {
  components: {
    PageContentMarkdown,
    Breadcrumb,
    PageLayout
  },
  props: {
    noBreadcrumb: {
      type: Boolean,
      default: false
    },
    id: {
      type: Number,
      default: -1
    }
  },
  data() {
    return {
      page: {}
    };
  },
  created() {
    return this.fetchData();
  },
  methods: {
    async fetchData() {
      try {
        const res = await ContentService.getPageContent(this.id);
        this.page = res.data;
      } catch (err) {
        // force the component destroy to remove it from the keep-alive cache
        this.$destroy();
        this.$router.replace('/404');
      }
    }
  }
};
</script>

<style lang="scss">
.breadcrumb {
  margin-top: 10px;
}
</style>
