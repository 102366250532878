<template>
  <div class="page-content text-justify" v-html="value"></div>
</template>

<script>
import Session from '@/services/session';

export default {
  props: {
    value: {
      type: String,
      default: ''
    }
  },
  watch: {
    value(val) {
      if (val) {
        this.$nextTick(() => {
          let nodes = document.querySelectorAll('.page-content a');
          nodes.forEach(node => {
            if (node.href && node.href.indexOf('access=private') > -1 && Session.isActive()) {
              node.href += '&token=' + Session.getSessionToken();
            }
          });
        });
      }
    }
  }
};
</script>

<style lang="scss">
.content {
  p {
    font-size: 16px;
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
