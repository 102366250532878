<template>
  <div class="box box-right">
    <div class="container">
      <h3>Cadastro de nova senha</h3>
      <hr />
      <p>Digite sua nova senha abaixo.</p>

      <b-form v-if="!showSuccessMessage" @submit="onSubmit">
        <b-form-group label="Nova senha*" class="text-left">
          <b-form-input v-model="form.senha" type="password" required maxlength="128" />
        </b-form-group>

        <b-form-group label="Nova senha confirmação*" class="text-left">
          <b-form-input v-model="form.novaSenhaConfirm" type="password" required maxlength="128" />
          <b-form-text v-if="invalidConfirmation" text-variant="danger">
            O valor da confirmação da nova senha não está igual ao preenchido em "Nova Senha*".
          </b-form-text>
        </b-form-group>

        <b-button type="submit" variant="danger" :disabled="disableUI">
          <b-spinner v-show="disableUI" small type="grow"></b-spinner>
          Alterar Senha
        </b-button>
      </b-form>

      <p v-if="showSuccessMessage" class="alert-success">
        Sua senha foi alterada com sucesso.
        <br />
        Clique <router-link to="/">AQUI</router-link> para voltar.
      </p>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  props: {
    token: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      show: true,
      form: {
        senha: '',
        novaSenhaConfirm: ''
      },
      disableUI: false,
      loaded: false,
      invalidConfirmation: false,
      showSuccessMessage: false
    };
  },
  methods: {
    async onSubmit(evt) {
      evt.preventDefault();
      this.disableUI = true;
      try {
        await axios.post(`/senha/alterar?token=${this.token}`, this.form);
        this.showSuccessMessage = true;
      } finally {
        this.disableUI = false;
      }
    }
  }
};
</script>

<style lang="scss">
.box-right {
  hr {
    margin-left: 0;
    margin-right: 0;
  }
  button {
    min-width: 200px;
    margin-top: 40px;
  }
  p {
    font-size: 14px;
  }
}
</style>
