<template>
  <header v-if="!isLoading" class="container">
    <div class="row flex-nowrap justify-content-between align-items-center">
      <div class="col-10 text-left banner">
        <Markdown :content="bannerHTML" />
      </div>
      <div class="col-2 text-right">
        <ButtonLogin />
      </div>
    </div>
  </header>
</template>

<script>
import ContentService from '@/services/content-service';
import Markdown from './Markdown.vue';
import ButtonLogin from './ButtonLogin.vue';

export default {
  components: {
    Markdown,
    ButtonLogin
  },
  data() {
    return {
      bannerHTML: null,
      isLoading: true
    };
  },
  created() {
    this.isLoading = true;
    this.fetchData();
  },
  methods: {
    async fetchData() {
      try {
        const res = await ContentService.getPageContent(16);
        this.bannerHTML = res.data.conteudo;
      } catch (err) {
        this.bannerHTML = `<img src="/img/logo.png" /><img src="/img/rockafe.png" />`;
      } finally {
        this.isLoading = false;
      }
    }
  }
};
</script>
